/* eslint-disable react/prop-types */
import React, { useState } from "react";
import Button from "@mui/material/Button";
import {
  USER_BUTTON_OPTION_TEMP_USER,
  USER_BUTTON_OPTION_WITHOUT_LEAVE,
  USER_BUTTON_OPTION_ALUMNI_USER,
} from "../Common/Common";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import SettingsIcon from "@mui/icons-material/Settings";
import IconButton from "@mui/material/IconButton";
import styled from "@emotion/styled";

const StyledButton = styled(Button)`
  width: 100%;
`;

function ShowButtonSelect({
  handleprintRun,
  handleRun,
  handleRunSms,
  handleRunLeave,
  handleRunLock,
  handleEmail,
  handleUnit,
  handleRunName,
  honbuid,
  name,
  unitname,
  hpname,
  hpcode,
  unitcode,
  ownvalue,
}) {
  const [stampAnchorEl, setStampAnchorEl] = useState(null);
  const handleStampClick = (event) => {
    setStampAnchorEl(event.currentTarget);
  };
  const handleStampClose = () => {
    setStampAnchorEl(null);
  };

  return (
    <>
      <IconButton aria-label="delete" onClick={handleStampClick}>
        <SettingsIcon />
      </IconButton>

      {ownvalue == "alumni" ? (
        <Menu
          id="simple-menu"
          anchorEl={stampAnchorEl}
          keepMounted
          open={Boolean(stampAnchorEl)}
          onClose={handleStampClose}
        >
          {USER_BUTTON_OPTION_ALUMNI_USER.map((title) => {
            return (
              <MenuItem key={title} value={title}>
                <StyledButton
                  variant="contained"
                  key={title}
                  onClick={() => {
                    if (title == "ロック解除") {
                      handleRunLock(honbuid);
                      setStampAnchorEl(null);
                    }
                    if (title == "QRコード印刷") {
                      handleprintRun(honbuid);
                      setStampAnchorEl(null);
                    }
                    if (title == "パスワード初期化") {
                      handleRun(honbuid);
                      setStampAnchorEl(null);
                    }
                    if (title == "電話番号初期化") {
                      handleRunSms(honbuid);
                      setStampAnchorEl(null);
                    }
                    if (title == "名前変更") {
                      handleRunName(honbuid, name);
                      setStampAnchorEl(null);
                    }
                  }}
                >
                  {title}
                </StyledButton>
              </MenuItem>
            );
          })}
        </Menu>
      ) : ownvalue == "1" ||
        ["1017", "1007", "4015", "5028", "1022", "1042"].includes(hpcode) ||
        honbuid >= "1000000" ? (
        <Menu
          id="simple-menu"
          anchorEl={stampAnchorEl}
          keepMounted
          open={Boolean(stampAnchorEl)}
          onClose={handleStampClose}
        >
          {USER_BUTTON_OPTION_TEMP_USER.map((title) => {
            return (
              <MenuItem key={title} value={title}>
                <StyledButton
                  variant="contained"
                  key={title}
                  onClick={() => {
                    if (title == "ロック解除") {
                      handleRunLock(honbuid);
                      setStampAnchorEl(null);
                    }
                    if (title == "QRコード印刷") {
                      handleprintRun(honbuid);
                      setStampAnchorEl(null);
                    }
                    if (title == "パスワード初期化") {
                      handleRun(honbuid);
                      setStampAnchorEl(null);
                    }
                    if (title == "電話番号初期化") {
                      handleRunSms(honbuid);
                      setStampAnchorEl(null);
                    }
                    if (title == "名前変更") {
                      handleRunName(honbuid, name);
                      setStampAnchorEl(null);
                    }
                    if (title == "Email変更") {
                      handleEmail(honbuid);
                      setStampAnchorEl(null);
                    }
                    if (title == "部署変更") {
                      handleUnit(honbuid, hpcode, hpname, unitcode, unitname);
                      setStampAnchorEl(null);
                    }
                    if (title == "退職") {
                      handleRunLeave(honbuid);
                      setStampAnchorEl(null);
                    }
                  }}
                >
                  {title}
                </StyledButton>
              </MenuItem>
            );
          })}
        </Menu>
      ) : (
        <Menu
          id="simple-menu"
          anchorEl={stampAnchorEl}
          keepMounted
          open={Boolean(stampAnchorEl)}
          onClose={handleStampClose}
        >
          {USER_BUTTON_OPTION_WITHOUT_LEAVE.map((title) => (
            <MenuItem key={title} value={title}>
              <StyledButton
                variant="contained"
                key={title}
                onClick={() => {
                  if (title == "ロック解除") {
                    handleRunLock(honbuid);
                    setStampAnchorEl(null);
                  }
                  if (title == "QRコード印刷") {
                    handleprintRun(honbuid);
                    setStampAnchorEl(null);
                  }
                  if (title == "パスワード初期化") {
                    handleRun(honbuid);
                    setStampAnchorEl(null);
                  }
                  if (title == "電話番号初期化") {
                    handleRunSms(honbuid);
                    setStampAnchorEl(null);
                  }
                  if (title == "名前変更") {
                    handleRunName(honbuid, name);
                    setStampAnchorEl(null);
                  }
                  if (title == "Email変更") {
                    handleEmail(honbuid);
                    setStampAnchorEl(null);
                  }
                  if (title == "部署変更") {
                    handleUnit(honbuid, hpcode, hpname, unitcode, unitname);
                    setStampAnchorEl(null);
                  }
                }}
              >
                {title}
              </StyledButton>
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
}

export default ShowButtonSelect;
