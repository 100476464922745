/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import PageviewOutlinedIcon from "@mui/icons-material/PageviewOutlined";
import PageviewRoundedIcon from "@mui/icons-material/PageviewRounded";
import Snackbar from "@mui/material/Snackbar";
import React, { useEffect, useState, useContext, useMemo } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Tooltip from "@mui/material/Tooltip";
import { API_URL, STATIC_URL, custom_axios, custom_axios_post, USER_SELECT_OPTION } from "./Common";
import { useHistory } from "react-router-dom";
import { Store } from "../Store/store";
import ShowCommon from "./ShowCommon";
import ShowChangeName from "./ShowChangeName";
import ShowHonbuidInput from "./ShowHonbuidInput";
import { MultiSelect } from "react-multi-select-component";
import Slide from "@mui/material/Slide";
import styled from "@emotion/styled";
import ShowUserSelect from "./ShowUserSelect";
import ShowButtonSelect from "./ShowButtonSelect";
import ShowPrint from "./ShowPrint";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import {
  DataGridPro as DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  gridPageCountSelector,
  GridPagination,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-pro";
import MuiPagination from "@mui/material/Pagination";
const StyledDataGrid = styled("div")`
  height: 100%;
  weight: 100%;
`;
const StyledRapDataGrid = styled("div")`
  height: calc(100vh - 130px);
`;
const StyledCircularProgress = styled(CircularProgress)`
  color: ${(props) => props.theme.palette.primary.light};
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12;
  margin-left: -12;
`;

function Pagination({ page, onPageChange, className }) {
  const apiRef = useGridApiContext();

  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1);
      }}
    />
  );
}

function CustomPagination(props) {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
}

const StyledCsvDiv = styled("div")`
  display: flex;
`;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const headCells = [
  { id: "honbuid", label: "本部ID" },
  { id: "name", label: "名前" },
  { id: "hpname", label: "病院名" },
  { id: "jobname", label: "職種" },
  { id: "leaveday", label: "退職日" },
  { id: "time", label: "ログイン時刻" },
  { id: "islocked", label: "ロック" },
  { id: "salary", label: "ユーザー権限" },
  { id: "edit", label: "設定" },
];

const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;
const StyledButton = styled(Button)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;
const StyledDialogContent = styled(DialogContent)`
  padding: 0px;
`;
const StyledRoot = styled("div")`
  width: 100%;
`;
function CustomToolbar() {
  //プロパティから utf8WithBom = Turue を渡してあげる。
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      {/* <GridToolbarDensitySelector /> */}
      <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
    </GridToolbarContainer>
  );
}
function ShowAlumniUser({ open, handleClose }) {
  const history = useHistory();
  const { state } = useContext(Store);

  const [rowId, setRowId] = useState([]);

  /*  */
  const [openHonbuidInput, setOpenHonbuidInput] = useState(false);
  const honbuidSearch = () => {
    setOpenHonbuidInput(!openHonbuidInput);
  };
  const handleHonbuidInputClose = () => {
    setOpenHonbuidInput(false);
  };
  const [inputhonbuids, setInputHonbuids] = useState("");
  const searchHonbuidProc = async (word) => {
    setInputHonbuids(word);
    let honbuid = localStorage.getItem("honbuid");
    const body = {
      searchword: word,
      honbuid: honbuid,
    };
    const result = await custom_axios_post(API_URL + "/GetAlumniUsersHonbuid", body);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      setLoading(false);

      setMembersList(result.data.users);
    }
    handleHonbuidInputClose();
  };

  /* スナックバーのON/OFF用 */
  const [passsnackopen, setPassSnackOpen] = useState(false);
  const [smssnackopen, setSmsSnackOpen] = useState(false);
  const [locksnackopen, setLockSnackOpen] = useState(false);
  const [snackopen, setSnackOpen] = useState(false);

  /* 本体のList */
  const [membersList, setMembersList] = useState([]);
  const [membersListColumns, setMembersListColumns] = useState([]);

  /* 検索のDDL */
  const [approvalList, setApprovalList] = useState([
    { label: "承認", value: "0" },
    { label: "未承認", value: "1" },
  ]);
  const [selectedApprovalList, setSelectedApprovalList] = useState([{ label: "承認", value: "0" }]);

  const [hospitalList, setHospitalList] = useState([]);
  const [selectedHospitalList, setSelectedHospitalList] = useState([]);

  const [jobList, setJobList] = useState([]);
  const [selectedJobList, setSelectedJobList] = useState([]);

  const [nendoList, setNendoList] = useState([]);
  const [selectedNendoList, setSelectedNendoList] = useState([]);

  const [loading, setLoading] = useState(false);

  /* DDLの値変更時に、毎回実行される */
  const proc = async (lApproval, lHospital, lJob, lNendo) => {
    setLoading(true);

    let joinapproval = lApproval
      .map((e) => {
        return e.value;
      })
      .join("_");

    let joinhospital = lHospital
      .map((e) => {
        return e.value;
      })
      .join("_");

    let joinjob = lJob
      .map((e) => {
        return e.value;
      })
      .join("_");

    let joinnendo = lNendo
      .map((e) => {
        return e.value;
      })
      .join("_");

    if (joinapproval.length == 0 || joinhospital.length == 0 || joinjob.length == 0 || joinnendo.length == 0) {
      setMembersList([]);
      return;
    }
    let honbuid = localStorage.getItem("honbuid");
    const body = {
      honbuid: honbuid,
      approvals: joinapproval,
      hps: joinhospital,
      jobs: joinjob,
      nendos: joinnendo,
    };
    const result = await custom_axios_post(API_URL + "/GetAlumniUsers", body);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      setLoading(false);
      setMembersList(result.data.users);
    }
  };
  /* 初回に1回だけ実行、そのためDDLの値セットも取得しておく */
  const initproc = async () => {
    setLoading(true);

    let honbuid = localStorage.getItem("honbuid");
    const body = {
      honbuid: honbuid,
    };
    const result = await custom_axios_post(API_URL + "/GetAlumniUsers", body);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      setLoading(false);
      setApprovalList([
        { label: "承認", value: "0" },
        { label: "未承認", value: "1" },
      ]);
      const localhospitallist = result.data.hps.map((e) => {
        return { label: e.label, value: e.value };
      });
      setHospitalList(localhospitallist);
      const localjoblist = result.data.jobs.map((e) => {
        return { label: e.label, value: e.value };
      });
      setJobList(localjoblist);
      const localnendolist = result.data.nendos.map((e) => {
        return { label: e.label, value: e.value };
      });
      setNendoList(localnendolist);

      setSelectedApprovalList([{ label: "承認", value: "0" }]);
      setSelectedHospitalList(localhospitallist);
      setSelectedJobList(localjoblist);
      setSelectedNendoList(result.data.selectednendo);

      setMembersList(result.data.users);
    }
  };

  useEffect(() => {
    if (open) {
      setMembersListColumns(
        headCells.map((v) => {
          if (v.id == "honbuid") {
            return {
              field: v.id,
              headerName: v.label,
              width: 80,
            };
          }
          if (v.id == "islocked") {
            return {
              field: v.id,
              headerName: v.label,
              // width: 70,
              valueGetter: (value, row) => {
                if (row.islocked == "1") {
                  return "ロック";
                }
                return "";
              },
              renderCell: (params) => {
                return <div>{params.row.islocked === "1" ? "ロック" : null}</div>;
              },
            };
          }
          if (v.id == "salary") {
            return {
              field: v.id,
              headerName: v.label,
              width: 300,
              valueGetter: (value, row) => {
                let retstr = "";
                if (row.issalary == "1") {
                  retstr += "WEB給与";
                }
                if (row.isphr == "1") {
                  retstr += "PHR";
                }
                if (row.isloginok == "1") {
                  retstr += "ログイン可";
                }
                if (row.isvip == "1") {
                  retstr += "VIP";
                }
                if (row.isanpi == "1") {
                  retstr += "安否";
                }
                if (row.isnipo == "1") {
                  retstr += "日報";
                }
                if (row.iselearning == "1") {
                  retstr += "Eラーニング";
                }
                return retstr;
              },
              renderCell: (params) => {
                return (
                  <ShowUserSelect
                    initvalue={calcInitvalue(
                      params.row.honbuid,
                      params.row.issalary,
                      params.row.isphr,
                      params.row.isloginok,
                      params.row.isvip,
                      params.row.isanpi,
                      params.row.isnipo,
                      params.row.iselearning
                    )}
                    callBack={handleChangeUserOption}
                    honbuid={params.row.honbuid}
                    ownvalue={state.isadmin}
                  />
                );
              },
            };
          }
          if (v.id == "edit") {
            return {
              field: v.id,
              headerName: v.label,
              // width: 130,

              renderCell: (params) => {
                return (
                  <ShowButtonSelect
                    //callBack={handleChangeUserOption}
                    handleprintRun={handleprintRun}
                    handleRun={handleRun}
                    handleRunSms={handleRunSms}
                    handleRunLeave={() => {}}
                    handleRunLock={handleRunLock}
                    handleEmail={() => {}}
                    handleUnit={() => {}}
                    handleRunName={handleRunName}
                    honbuid={params.row.honbuid}
                    name={params.row.name}
                    unitname={params.row.unitname}
                    jobname={params.row.jobname}
                    hpname={params.row.hpname}
                    hpcode={params.row.hpcode}
                    unitcode={params.row.unitcode}
                    ownvalue={"alumni"}
                  ></ShowButtonSelect>
                );
              },
            };
          }
          if (["time", "leaveday"].includes(v.id)) {
            return {
              field: v.id,
              headerName: v.label,
              width: 160,
            };
          }
          if (v.id == "name") {
            return {
              field: v.id,
              headerName: v.label,
              width: 200,
              valueGetter: (value, row) => {
                return row.name;
              },
              renderCell: (params) => {
                return (
                  <div className="member-content">
                    <img
                      src={
                        STATIC_URL +
                        `/downloadAvatar?honbuid=${params.row.honbuid}&fileno=${params.row.fileno}&size=small`
                      }
                      width="30"
                      height="30"
                      alt=""
                      className="member-image"

                      //style={isCurrent ? { border: "1px solid white" } : null}
                    />
                    <div>{params.row.name}</div>
                  </div>
                );
              },
            };
          }
          return { field: v.id, headerName: v.label };
        })
      );

      initproc([{ label: "承認", value: "0" }], [], [], [{ label: "2024年度", value: "2024" }]);
    }
  }, [open]);

  const [openLockModal, setOpenLockModal] = useState(false);
  const [openSmsModal, setOpenSmsModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openPrintModal, setOpenPrintModal] = useState(false);

  const [openNameModal, setOpenNameModal] = useState({ open: false, honbuid: "", name: "" });
  const [delid, setDelId] = useState(0);
  const handleLockModalClose = () => {
    setOpenLockModal(false);
  };

  const handleSmsModalClose = () => {
    setOpenSmsModal(false);
  };
  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handlePrintModalClose = () => {
    setOpenPrintModal(false);
  };

  const handleNameModalClose = () => {
    setOpenNameModal({ open: false, honbuid: "", name: "" });
  };

  const handleInitSms = async () => {
    let strurl = API_URL + `/initSms?honbuid=${delid}`;
    const result = await custom_axios(strurl);
    const status = result.status;
    setSmsSnackOpen(true);
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      var resultlist = [];

      for (let j = 0; j < membersList.length; j++) {
        var inserted = false;
        for (let i = 0; i < rowId.length; i++) {
          if (rowId[i] == membersList[j].honbuid) {
            resultlist.push({ ...membersList[j], isphone: "" });
            inserted = true;
          }
        }
        if (inserted == false) {
          resultlist.push({ ...membersList[j] });
        }
      }
      setMembersList(resultlist);

      setOpenSmsModal(false);
    }
  };

  const handleInitPass = async () => {
    let strurl = API_URL + `/initPasswordRand?honbuid=${delid}`;
    const result = await custom_axios(strurl);
    const status = result.status;
    setPassSnackOpen(true);
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      setOpenModal(false);
    }
  };

  const handleInitLock = async () => {
    let strurl = API_URL + `/initLock?honbuid=${delid}`;
    const result = await custom_axios(strurl);
    const status = result.status;
    setLockSnackOpen(true);
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      setMembersList(
        membersList.map((item) => {
          if (item.honbuid === delid) {
            return { ...item, islocked: "0" };
          }
          return item;
        })
      );

      setOpenLockModal(false);
    }
  };

  const handleRunLock = async (honbuid) => {
    setDelId(honbuid);
    setOpenLockModal(true);
  };

  const handleRunSms = async (honbuid) => {
    setDelId(honbuid);
    setOpenSmsModal(true);
  };

  const handleRun = async (honbuid) => {
    setDelId(honbuid);
    setOpenModal(true);
  };
  const handleprintRun = async (honbuid) => {
    setRowId([honbuid]);
    setOpenPrintModal(true);
  };

  const handleChangeUserOption = async (honbuid, issalary, isphr, islogin, isvip, isanpi, isnipo, iselearning) => {
    const result = await custom_axios(
      API_URL +
        `/changeUserOption?honbuid=${honbuid}&issalary=${issalary}&isphr=${isphr}&islogin=${islogin}&isvip=${isvip}&isanpi=${isanpi}&isnipo=${isnipo}&iselearning=${iselearning}`
    );
    const status = result.status;

    if (status === 200) {
      setMembersList((m) =>
        m.map((item) => {
          if (item.honbuid === honbuid) {
            return {
              ...item,
              issalary: issalary,
              isphr: isphr,
              isloginok: islogin,
              isvip: isvip,
              isanpi: isanpi,
              isnipo: isnipo,
              iselearning: iselearning,
            };
          }
          return item;
        })
      );
      setSnackOpen(true);
    } else if (status === 204) {
      // setSnackSalaryOpen(true);
    } else if (status === 401) {
      history.replace("/loginError");
    }
  };

  const calcInitvalue = (honbuid, issalary, isphr, isloginok, isvip, isanpi, isnipo, iselearning) => {
    const initvalue = [];
    if (isloginok == "1") {
      initvalue.push(USER_SELECT_OPTION[0]);
    }
    if (issalary == "1") {
      initvalue.push(USER_SELECT_OPTION[1]);
    }
    if (isphr == "1") {
      initvalue.push(USER_SELECT_OPTION[2]);
    }
    if (isnipo == "1") {
      initvalue.push(USER_SELECT_OPTION[3]);
    }
    if (iselearning == "1") {
      initvalue.push(USER_SELECT_OPTION[4]);
    }

    if (isvip == "1") {
      initvalue.push(USER_SELECT_OPTION[5]);
    }
    if (isanpi == "1") {
      initvalue.push(USER_SELECT_OPTION[6]);
    }

    return initvalue;
  };

  const handleRunName = (honbuid, name) => {
    setOpenNameModal({ open: true, honbuid: honbuid, name: name });
  };
  const afterSaveNameProc = (honbuid, name) => {
    var localmeberList = membersList.map((item) => {
      if (item.honbuid === honbuid) {
        return { ...item, name: name };
      }
      return item;
    });

    setMembersList(localmeberList);
  };
  const MemoChangeName = useMemo(() => {
    return (
      <ShowChangeName
        open={openNameModal.open}
        handleClose={handleNameModalClose}
        afterSaveNameProc={afterSaveNameProc}
        honbuid={openNameModal.honbuid}
        username={openNameModal.name}
      ></ShowChangeName>
    );
  }, [openNameModal]);

  const MemoSMS = useMemo(() => {
    return (
      <ShowCommon
        open={openSmsModal}
        title="SMS認証を解除しますか？"
        handleClose={handleSmsModalClose}
        handleCallBack={handleInitSms}
      ></ShowCommon>
    );
  }, [openSmsModal]);
  const MemoInit = useMemo(() => {
    return (
      <ShowCommon
        open={openModal}
        title="初期化しますか？"
        handleClose={handleModalClose}
        handleCallBack={handleInitPass}
      ></ShowCommon>
    );
  }, [openModal]);

  const MemoLock = useMemo(() => {
    return (
      <ShowCommon
        open={openLockModal}
        title="ロックを解除しますか？"
        handleClose={handleLockModalClose}
        handleCallBack={handleInitLock}
      ></ShowCommon>
    );
  }, [openLockModal]);

  const MemoHonbuidInput = useMemo(() => {
    return (
      <ShowHonbuidInput
        open={openHonbuidInput}
        handleClose={handleHonbuidInputClose}
        callBackProc={searchHonbuidProc}
        afterSaveProc={() => proc(selectedApprovalList, selectedHospitalList, selectedJobList, selectedNendoList)}
        honbuids={inputhonbuids}
        setHonbuids={setInputHonbuids}
      ></ShowHonbuidInput>
    );
  }, [openHonbuidInput, inputhonbuids]);

  const MemoPrint = useMemo(() => {
    return <ShowPrint open={openPrintModal} handleClose={handlePrintModalClose} honbuids={rowId}></ShowPrint>;
  }, [openPrintModal, rowId]);
  const [sortModel, setSortModel] = React.useState([]);
  const [filterModel, setFilterModel] = React.useState({
    items: [],
  });
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({});
  // const [openFa, setOpenFa] = useState(false);
  // const handleFaClickOpen = () => {
  //   setOpenFa(true);
  // };
  // const handleFaClose = () => {
  //   setOpenFa(false);
  // };
  // const MemoCreateAccount = useMemo(() => {
  //   return (
  //     <ShowCreateAlumniAccount
  //       open={openFa}
  //       handleClose={handleFaClose}
  //       afterSaveProc={afterSaveProc}
  //       setNewUser={setNewUser}
  //       hpcode={state.hpcode}
  //       hpname={state.hpname}
  //     ></ShowCreateAlumniAccount>
  //   );
  // }, [openFa]);
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"xl"}
      TransitionComponent={Transition}
    >
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">
          <div className="show-member-search">退職者一覧</div>
          <div className="question-title-actions">
            {/* <StyledButton
              onClick={() => {
                handleFaClickOpen();
              }}
              variant="outlined"
            >
              一時ユーザー作成
            </StyledButton> */}
            <StyledButton onClick={handleClose} variant="outlined">
              閉じる (Esc)
            </StyledButton>
          </div>
        </div>
      </StyledDialogTitle>
      <StyledDialogContent>
        {MemoChangeName}
        {MemoHonbuidInput}
        {MemoSMS}
        {MemoInit}
        {MemoLock}
        {MemoPrint}

        <Snackbar
          open={snackopen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={() => {
            setSnackOpen(false);
          }}
          message="ユーザー情報を変更しました。"
        ></Snackbar>
        <Snackbar
          open={smssnackopen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={() => {
            setSmsSnackOpen(false);
          }}
          message="SMS認証を解除しました。"
        ></Snackbar>
        <Snackbar
          open={passsnackopen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={10000}
          onClose={() => {
            setPassSnackOpen(false);
          }}
          message="ランダムな値に初期化しました。QRコード印刷をしてパスワード確認をお願いします。"
        ></Snackbar>

        <Snackbar
          open={locksnackopen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={() => {
            setLockSnackOpen(false);
          }}
          message="ロックを解除しました。"
        ></Snackbar>

        <StyledRoot>
          <div className="user-select-area">
            {inputhonbuids == "" ? (
              <div className="user-select-subarea">
                <div className="multi-select">
                  <MultiSelect
                    options={approvalList}
                    value={selectedApprovalList}
                    onChange={(e) => {
                      setSelectedApprovalList(e);
                      proc(e, selectedHospitalList, selectedJobList, selectedNendoList);
                    }}
                    labelledBy={"Select"}
                    overrideStrings={{
                      selectSomeItems: "選択...",
                      allItemsAreSelected: "全選択",
                      selectAll: "全選択",
                      search: "検索",
                      clearSearch: "検索クリア",
                    }}
                    filterOptions={(options, filter) => {
                      if (!filter) {
                        return options;
                      }
                      const re = new RegExp(filter, "i");
                      return options.filter(({ label }) => label && label.match(re));
                    }}
                  />
                </div>
                <div className="multi-select">
                  <MultiSelect
                    options={hospitalList}
                    value={selectedHospitalList}
                    onChange={(e) => {
                      setSelectedHospitalList(e);
                      proc(selectedApprovalList, e, selectedJobList, selectedNendoList);
                    }}
                    labelledBy={"Select"}
                    overrideStrings={{
                      selectSomeItems: "選択...",
                      allItemsAreSelected: "全選択",
                      selectAll: "全選択",
                      search: "検索",
                      clearSearch: "検索クリア",
                    }}
                    filterOptions={(options, filter) => {
                      if (!filter) {
                        return options;
                      }
                      const re = new RegExp(filter, "i");
                      return options.filter(({ label }) => label && label.match(re));
                    }}
                  />
                </div>
                <div className="multi-select">
                  <MultiSelect
                    options={jobList}
                    value={selectedJobList}
                    onChange={(e) => {
                      setSelectedJobList(e);
                      proc(selectedApprovalList, selectedHospitalList, e, selectedNendoList);
                    }}
                    labelledBy={"Select"}
                    overrideStrings={{
                      selectSomeItems: "選択...",
                      allItemsAreSelected: "全選択",
                      selectAll: "全選択",
                      search: "検索",
                      clearSearch: "検索クリア",
                    }}
                    filterOptions={(options, filter) => {
                      if (!filter) {
                        return options;
                      }
                      const re = new RegExp(filter, "i");
                      return options.filter(({ label }) => label && label.match(re));
                    }}
                  />
                </div>
                <div className="multi-select">
                  <MultiSelect
                    options={nendoList}
                    value={selectedNendoList}
                    onChange={(e) => {
                      setSelectedNendoList(e);
                      proc(selectedApprovalList, selectedHospitalList, selectedJobList, e);
                    }}
                    labelledBy={"Select"}
                    overrideStrings={{
                      selectSomeItems: "選択...",
                      allItemsAreSelected: "全選択",
                      selectAll: "全選択",
                      search: "検索",
                      clearSearch: "検索クリア",
                    }}
                    filterOptions={(options, filter) => {
                      if (!filter) {
                        return options;
                      }
                      const re = new RegExp(filter, "i");
                      return options.filter(({ label }) => label && label.match(re));
                    }}
                  />
                </div>
              </div>
            ) : (
              <div></div>
            )}

            <StyledCsvDiv>
              <Tooltip title="本部ID・名前で検索">
                <IconButton color="primary" aria-label="send message" onClick={honbuidSearch}>
                  {inputhonbuids == "" ? <PageviewOutlinedIcon /> : <PageviewRoundedIcon />}
                </IconButton>
              </Tooltip>
            </StyledCsvDiv>
          </div>

          {loading ? (
            <StyledRapDataGrid>
              <StyledCircularProgress />
            </StyledRapDataGrid>
          ) : (
            <StyledRapDataGrid>
              <StyledDataGrid>
                <DataGrid
                  columnVisibilityModel={columnVisibilityModel}
                  onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                  filterModel={filterModel}
                  onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
                  sortModel={sortModel}
                  onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                  getRowId={(row) => row.honbuid}
                  hideFooterSelectedRowCount={true}
                  rows={membersList}
                  columns={membersListColumns}
                  pagination
                  density={"compact"}
                  disableRowSelectionOnClick
                  slots={{
                    toolbar: CustomToolbar,
                    pagination: CustomPagination,
                  }}
                ></DataGrid>
              </StyledDataGrid>
            </StyledRapDataGrid>
          )}
        </StyledRoot>
      </StyledDialogContent>
    </Dialog>
  );
}

export default ShowAlumniUser;
