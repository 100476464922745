/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import PageviewOutlinedIcon from "@mui/icons-material/PageviewOutlined";
import PageviewRoundedIcon from "@mui/icons-material/PageviewRounded";
import Snackbar from "@mui/material/Snackbar";
import React, { useEffect, useState, useContext, useMemo } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
// import Search from "@mui/icons-material/Search";
// import Close from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
// import Link from "@mui/material/Link";
import { API_URL, STATIC_URL, custom_axios, custom_axios_post, USER_SELECT_OPTION } from "../Common/Common";
import { useHistory } from "react-router-dom";
import { Store } from "../Store/store";
import { ADMIN_BUTTON_OPTION } from "../Common/Common";
import Chip from "@mui/material/Chip";
import ShowCommon from "../Common/ShowCommon";
import ShowCommonPlus from "../Common/ShowCommonPlus";
import ShowCreateAccount from "../Common/ShowCreateAccount";
import ShowNewUser from "../Common/ShowNewUser";
import ShowJinji from "../Common/ShowJinji";
import ShowAdminSelect from "../Common/ShowAdminSelect";
import ShowBukaiAdminSelect from "../Common/ShowBukaiAdminSelect";
import ShowChangeName from "../Common/ShowChangeName";
import ShowUnitName from "../Common/ShowUnitName";
import ShowHonbuidInput from "../Common/ShowHonbuidInput";
import ShowUserEmail from "../Common/ShowUserEmail";
// import TableCell from "@mui/material/TableCell";
import { MultiSelect } from "react-multi-select-component";
import Slide from "@mui/material/Slide";
import styled from "@emotion/styled";
import ShowUserSelect from "./ShowUserSelect";
import ShowButtonSelect from "./ShowButtonSelect";
import ShowPrint from "./ShowPrint";
import SettingsIcon from "@mui/icons-material/Settings";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import CircularProgress from "@mui/material/CircularProgress";
import {
  DataGridPro as DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  // GridToolbarDensitySelector,
  GridToolbarExport,
  gridPageCountSelector,
  GridPagination,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-pro";
import MuiPagination from "@mui/material/Pagination";
const StyledDataGrid = styled("div")`
  height: 100%;
  weight: 100%;
`;
const StyledRapDataGrid = styled("div")`
  height: calc(100vh - 130px);
`;
const StyledCircularProgress = styled(CircularProgress)`
  color: ${(props) => props.theme.palette.primary.light};
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12;
  margin-left: -12;
`;

function Pagination({ page, onPageChange, className }) {
  const apiRef = useGridApiContext();

  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1);
      }}
    />
  );
}

function CustomPagination(props) {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
}

const StyledMenuButton = styled(Button)`
  width: 100%;
`;
const StyledIconButton = styled(IconButton)`
  margin-left: 5px;
`;
const StyledCsvDiv = styled("div")`
  display: flex;
`;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});
const StyledChip = styled(Chip)`
  margin-right: 5px;
  height: 15px;
  font-size: 8px;
`;
const headCells = [
  { id: "name", label: "名前" },
  { id: "honbuid", label: "本部ID" },
  { id: "hpname", label: "病院名" },
  { id: "unitname", label: "部署" },
  { id: "jobname", label: "職種" },
  { id: "bukainame", label: "部会" },
  { id: "isbukaiadmin", label: "部会管理" },
  { id: "opt", label: "機種" },
  { id: "time", label: "ログイン時刻" },
  { id: "islocked", label: "ロック" },
  { id: "isphone", label: "電話登録" },
  { id: "email", label: "Email" },
  { id: "isadmin", label: "管理者ロール" },
  { id: "salary", label: "ユーザー権限" },
  { id: "edit", label: "設定" },
];

const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;
const StyledButton = styled(Button)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;
const StyledDialogContent = styled(DialogContent)`
  padding: 0px;
`;
const StyledRoot = styled("div")`
  width: 100%;
`;
function CustomToolbar() {
  //プロパティから utf8WithBom = Turue を渡してあげる。
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      {/* <GridToolbarDensitySelector /> */}
      <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
    </GridToolbarContainer>
  );
}
function ShowUser({ open, handleClose }) {
  const history = useHistory();
  const { state } = useContext(Store);

  const [rowId, setRowId] = useState([]);

  const honbuidSearch = () => {
    setOpenHonbuidInput(!openHonbuidInput);
  };
  const handleHonbuidInputClose = () => {
    setOpenHonbuidInput(false);
  };
  const [openHonbuidInput, setOpenHonbuidInput] = useState(false);

  const [inputhonbuids, setInputHonbuids] = useState("");
  const searchHonbuidProc = async (word) => {
    setInputHonbuids(word);
    let honbuid = localStorage.getItem("honbuid");
    const body = {
      searchword: word,
      honbuid: honbuid,
    };
    const result = await custom_axios_post(API_URL + "/GetAdminUsersHonbuid", body);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      setLoading(false);

      setMembersList(
        result.data.users.map((e) => {
          return { ischecked: false, id: e.honbuid, ...e };
        })
      );
    }
    handleHonbuidInputClose();
  };

  const [openFa, setOpenFa] = useState(false);
  const handleFaClickOpen = () => {
    setOpenFa(true);
  };
  const handleFaClose = () => {
    setOpenFa(false);
  };

  const [openJin, setOpenJin] = useState(false);
  const handleJinClickOpen = () => {
    setOpenJin(true);
  };
  const handleJinClose = () => {
    setOpenJin(false);
  };
  const handleNewUserClose = () => {
    setNewUser({ ...newuser, open: false });
  };
  const [passsnackopen, setPassSnackOpen] = useState(false);
  const [smssnackopen, setSmsSnackOpen] = useState(false);
  const [codesnackopen, setCodeSnackOpen] = useState(false);
  const [leavesnackopen, setLeaveSnackOpen] = useState(false);
  const [locksnackopen, setLockSnackOpen] = useState(false);

  const [salaryAllSnackOpen, setSalaryAllSnackOpen] = useState(false);
  const [loginAllSnackOpen, setLoginAllSnackOpen] = useState(false);
  const [phrAllSnackOpen, setPhrAllSnackOpen] = useState(false);
  const [nipoAllSnackOpen, setNipoAllSnackOpen] = useState(false);
  const [elAllSnackOpen, setElAllSnackOpen] = useState(false);

  const [snackopen, setSnackOpen] = useState(false);
  const [uppersnackopen, setUpperSnackOpen] = useState(false);
  const [membersList, setMembersList] = useState([]);
  const [membersListColumns, setMembersListColumns] = useState([]);
  const [unitoptions, setUnitOptions] = useState([]);
  const [selectedunit, setSelectedUnit] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedhp, setSelectedHp] = useState([]);
  const [newuser, setNewUser] = useState({ open: false, id: "" });
  const [tempoptions] = useState([
    { label: "通常ユーザー", value: "0" },
    { label: "一時ユーザー", value: "1" },
  ]);
  const [osoptions] = useState([
    { label: "iOS", value: "iOS" },
    { label: "Android", value: "Android" },
    { label: "なし", value: "not" },
  ]);
  const [selectedtemp, setSelectedTemp] = useState([]);
  const [selectedlock, setSelectedLock] = useState([]);
  const [selectedos, setSelectedOs] = useState([]);
  const [loading, setLoading] = useState(false);

  const proc = async (localhps, localunits, localtemps, locallocks, localoss) => {
    setLoading(true);
    setSelectAllClick(false);
    let joinhps = localhps
      .map((e) => {
        return e.value;
      })
      .join("_");

    let joinunits = localunits
      .map((e) => {
        return e.value;
      })
      .join("_");

    var tempkbn = "";
    var localtempsvalues = localtemps.map((e) => {
      return e.value;
    });
    if (localtempsvalues.includes("0") && localtempsvalues.includes("1")) {
      tempkbn = "2"; /* 通常＋一時 */
    } else if (localtempsvalues.includes("0")) {
      tempkbn = "0"; /* 通常 */
    } else {
      tempkbn = "1"; /* 一時 */
    }

    var lockkbn = "";
    var locallockvalues = locallocks.map((e) => {
      return e.value;
    });
    if (locallockvalues.includes("0") && locallockvalues.includes("1")) {
      lockkbn = "2"; /* 両方 */
    } else if (locallockvalues.includes("0")) {
      lockkbn = "0"; /* 未ロック */
    } else {
      lockkbn = "1"; /* ロック中 */
    }

    let joinoss = localoss
      .map((e) => {
        return e.value;
      })
      .join("_");

    if (
      joinhps.length == 0 ||
      joinunits.length == 0 ||
      localtemps.length == 0 ||
      locallocks.length == 0 ||
      localoss.length == 0
    ) {
      setMembersList([]);
      return;
    }
    let honbuid = localStorage.getItem("honbuid");
    const body = {
      honbuid: honbuid,
      hascode: "1",
      hpcodes: joinhps,
      unitcodes: joinunits,
      tempkbn: tempkbn,
      lockkbn: lockkbn,
      oss: joinoss,
    };
    //const result = await custom_axios_post(API_URL + "/GetDebugAdminUsers", body);
    const result = await custom_axios_post(API_URL + "/GetAdminUsers", body);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      setLoading(false);
      setOptions(
        result.data.hps.map((e) => {
          return { label: e.hpname, value: e.hpcode };
        })
      );
      setUnitOptions(
        result.data.units.map((e) => {
          return { label: e.unitname, value: e.unitcode };
        })
      );
      setMembersList(
        result.data.users.map((e) => {
          return { ischecked: false, id: e.honbuid, ...e };
        })
      );
    }
  };
  const initproc = async (localhps, localunits, localtemps, locallocks, localoss) => {
    setLoading(true);
    setSelectAllClick(false);
    let joinhps = localhps
      .map((e) => {
        return e.value;
      })
      .join("_");
    var tempkbn = "";
    var localtempsvalues = localtemps.map((e) => {
      return e.value;
    });
    if (localtempsvalues.includes("0") && localtempsvalues.includes("1")) {
      tempkbn = "2"; /* 通常＋一時 */
    } else if (localtempsvalues.includes("0")) {
      tempkbn = "0"; /* 通常 */
    } else {
      tempkbn = "1"; /* 一時 */
    }

    var lockkbn = "";
    var locallockvalues = locallocks.map((e) => {
      return e.value;
    });
    if (locallockvalues.includes("0") && locallockvalues.includes("1")) {
      lockkbn = "2"; /* 両方 */
    } else if (locallockvalues.includes("0")) {
      lockkbn = "0"; /* 未ロック */
    } else {
      lockkbn = "1"; /* ロック中 */
    }

    let joinoss = localoss
      .map((e) => {
        return e.value;
      })
      .join("_");

    if (joinhps.length == 0 || localtemps.length == 0 || locallocks.length == 0 || localoss.length == 0) {
      setMembersList([]);
      return;
    }
    let honbuid = localStorage.getItem("honbuid");
    const body = {
      honbuid: honbuid,
      hascode: "1",
      hpcodes: joinhps,
      unitcodes: "",
      tempkbn: tempkbn,
      lockkbn: lockkbn,
      oss: joinoss,
    };
    const result = await custom_axios_post(API_URL + "/GetAdminUsers", body);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      setLoading(false);
      setOptions(
        result.data.hps.map((e) => {
          return { label: e.hpname, value: e.hpcode };
        })
      );
      setUnitOptions(
        result.data.units.map((e) => {
          return { label: e.unitname, value: e.unitcode };
        })
      );
      setSelectedUnit(
        result.data.units.map((e) => {
          return { label: e.unitname, value: e.unitcode };
        })
      );

      setMembersList(
        result.data.users.map((e) => {
          return { ischecked: false, id: e.honbuid, ...e };
        })
      );
    }
  };
  const afterSaveProc = async () => {
    setLoading(true);
    let joinhps = selectedhp
      .map((e) => {
        return e.value;
      })
      .join("_");

    // let joinjobs = selectedjob
    //   .map((e) => {
    //     return e.value;
    //   })
    //   .join("_");

    let joinunits = selectedunit
      .map((e) => {
        return e.value;
      })
      .join("_");

    var tempkbn = "";
    var localtempsvalues = selectedtemp.map((e) => {
      return e.value;
    });
    if (localtempsvalues.includes("0") && localtempsvalues.includes("1")) {
      tempkbn = "2"; /* 通常＋一時 */
    } else if (localtempsvalues.includes("0")) {
      tempkbn = "0"; /* 通常 */
    } else {
      tempkbn = "1"; /* 一時 */
    }

    var lockkbn = "";
    var locallockvalues = selectedlock.map((e) => {
      return e.value;
    });
    if (locallockvalues.includes("0") && locallockvalues.includes("1")) {
      lockkbn = "2"; /* 両方 */
    } else if (locallockvalues.includes("0")) {
      lockkbn = "0"; /* 未ロック */
    } else {
      lockkbn = "1"; /* ロック中 */
    }

    let joinoss = selectedos
      .map((e) => {
        return e.value;
      })
      .join("_");

    if (joinhps.length == 0 || joinunits.length == 0) {
      setMembersList([]);
      return;
    }
    let honbuid = localStorage.getItem("honbuid");
    const body = {
      honbuid: honbuid,
      hascode: "1",
      hpcodes: joinhps,
      unitcodes: joinunits,
      tempkbn: tempkbn,
      lockkbn: lockkbn,
      oss: joinoss,
    };
    const result = await custom_axios_post(API_URL + "/GetAdminUsers", body);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      setLoading(false);

      setMembersList(
        result.data.users.map((e) => {
          return { ischecked: false, id: e.honbuid, ...e };
        })
      );
      // if (searchText != "") {
      //   setMembersList(
      //     result.data.users
      //       .map((e) => {
      //         return { ischecked: false, id: e.honbuid, ...e };
      //       })
      //       .filter(
      //         (f) =>
      //           String(f.name).toLowerCase().indexOf(searchText) !== -1 || String(f.honbuid).indexOf(searchText) !== -1
      //       )
      //   );
      // } else {

      // }
    }
  };

  useEffect(() => {
    if (open) {
      setSelectedHp([{ label: state.hpcode + ":" + state.hpname, value: state.hpcode }]);
      setInputHonbuids("");

      setSelectedTemp([
        { label: "通常ユーザー", value: "0" },
        { label: "一時ユーザー", value: "1" },
      ]);
      setSelectedLock([
        { label: "ロック中", value: "1" },
        { label: "未ロック", value: "0" },
      ]);
      setSelectedUnit([{ label: state.unitcode + ":" + state.unitname, value: state.unitcode }]);
      setSelectedOs([
        { label: "iOS", value: "iOS" },
        { label: "Android", value: "Android" },
        { label: "なし", value: "not" },
      ]);

      setMembersListColumns(
        headCells.map((v) => {
          if (v.id == "honbuid") {
            return {
              field: v.id,
              headerName: v.label,
              width: 80,
            };
          }

          if (v.id == "opt") {
            return {
              field: v.id,
              headerName: v.label,
              width: 70,
              valueGetter: (value, row) => {
                if (row.isiod === "1") {
                  return "iOS";
                }
                if (row.isandroid === "1") {
                  return "Android";
                }
              },
              renderCell: (params) => {
                return (
                  <div>
                    {params.row.isiod === "1" ? (
                      <StyledChip variant="outlined" color="primary" size="small" label="iOS" />
                    ) : null}
                    {params.row.isandroid === "1" ? (
                      <StyledChip variant="outlined" size="small" label="Android" />
                    ) : null}
                  </div>
                );
              },
            };
          }
          if (v.id == "isbukaiadmin") {
            return {
              field: v.id,
              headerName: v.label,
              // width: 120,
              valueGetter: (value, row) => {
                if (row.isbukaiadmin == "1") {
                  return "管理";
                }
                if (row.isbukaiadmin == "2") {
                  return "部屋";
                }
                return "";
              },
              renderCell: (params) => {
                return (
                  <ShowBukaiAdminSelect
                    initvalue={params.row.isbukaiadmin}
                    callBack={handleChangeBukaiAdmin}
                    honbuid={params.row.honbuid}
                    editable={["1", "6"].includes(state.isadmin) || state.isbukaiadmin == "1"}
                  />
                );
              },
            };
          }
          if (v.id == "islocked") {
            return {
              field: v.id,
              headerName: v.label,
              // width: 70,
              valueGetter: (value, row) => {
                if (row.islocked == "1") {
                  return "ロック";
                }
                return "";
              },
              renderCell: (params) => {
                return <div>{params.row.islocked === "1" ? "ロック" : null}</div>;
              },
            };
          }
          if (v.id == "isphone") {
            return {
              field: v.id,
              headerName: v.label,
              // width: 70,
              valueGetter: (value, row) => {
                if (row.isphone == "1") {
                  return "●";
                }
                return "";
              },
              renderCell: (params) => {
                return <div>{params.row.isphone === "1" ? "●" : null}</div>;
              },
            };
          }

          if (v.id == "isadmin") {
            return {
              field: v.id,
              headerName: v.label,
              width: 180,
              valueGetter: (value, row) => {
                if (row.isadmin == "1") {
                  return "システム管理者";
                }
                if (row.isadmin == "2") {
                  return "施設管理者";
                }
                if (row.isadmin == "3") {
                  return "部屋";
                }
                if (row.isadmin == "4") {
                  return "ユーザー";
                }
                if (row.isadmin == "5") {
                  return "部屋・ユーザー";
                }
                if (row.isadmin == "6") {
                  return "施設横断部屋";
                }
                return "";
              },
              renderCell: (params) => {
                return (
                  // <TableCell align="left">
                  <ShowAdminSelect
                    ownvalue={state.isadmin}
                    initvalue={params.row.isadmin}
                    callBack={handleChangeAdmin}
                    honbuid={params.row.honbuid}
                    setUpperSnackOpen={setUpperSnackOpen}
                  />
                  // </TableCell>
                );
              },
            };
          }
          if (v.id == "salary") {
            return {
              field: v.id,
              headerName: v.label,
              width: 300,
              valueGetter: (value, row) => {
                let retstr = "";
                if (row.issalary == "1") {
                  retstr += "WEB給与";
                }
                if (row.isphr == "1") {
                  retstr += "PHR";
                }
                if (row.isloginok == "1") {
                  retstr += "ログイン可";
                }
                if (row.isvip == "1") {
                  retstr += "VIP";
                }
                if (row.isanpi == "1") {
                  retstr += "安否";
                }
                if (row.isnipo == "1") {
                  retstr += "日報";
                }
                if (row.iselearning == "1") {
                  retstr += "Eラーニング";
                }
                return retstr;
              },
              renderCell: (params) => {
                return (
                  <ShowUserSelect
                    initvalue={calcInitvalue(
                      params.row.honbuid,
                      params.row.issalary,
                      params.row.isphr,
                      params.row.isloginok,
                      params.row.isvip,
                      params.row.isanpi,
                      params.row.isnipo,
                      params.row.iselearning
                    )}
                    callBack={handleChangeUserOption}
                    honbuid={params.row.honbuid}
                    ownvalue={state.isadmin}
                  />
                );
              },
            };
          }
          if (v.id == "edit") {
            return {
              field: v.id,
              headerName: v.label,
              // width: 130,

              renderCell: (params) => {
                return (
                  <ShowButtonSelect
                    //callBack={handleChangeUserOption}
                    handleprintRun={handleprintRun}
                    handleRun={handleRun}
                    handleRunSms={handleRunSms}
                    handleRunLeave={handleRunLeave}
                    handleRunLock={handleRunLock}
                    handleEmail={handleEmail}
                    handleUnit={handleUnit}
                    handleRunName={handleRunName}
                    honbuid={params.row.honbuid}
                    name={params.row.name}
                    unitname={params.row.unitname}
                    jobname={params.row.jobname}
                    hpname={params.row.hpname}
                    hpcode={params.row.hpcode}
                    unitcode={params.row.unitcode}
                    ownvalue={state.isadmin}
                  ></ShowButtonSelect>
                );
              },
            };
          }

          if (v.id == "time") {
            return {
              field: v.id,
              headerName: v.label,
              width: 160,
            };
          }
          if (v.id == "name") {
            return {
              field: v.id,
              headerName: v.label,
              width: 200,
              valueGetter: (value, row) => {
                return row.name;
              },
              renderCell: (params) => {
                return (
                  <div className="member-content">
                    <img
                      src={
                        STATIC_URL +
                        `/downloadAvatar?honbuid=${params.row.honbuid}&fileno=${params.row.fileno}&size=small`
                      }
                      width="30"
                      height="30"
                      alt=""
                      className="member-image"

                      //style={isCurrent ? { border: "1px solid white" } : null}
                    />
                    <div>{params.row.name}</div>
                  </div>
                );
              },
            };
          }
          return { field: v.id, headerName: v.label };
        })
      );

      initproc(
        [{ label: state.hpname, value: state.hpcode }],
        [{ label: state.unitname, value: state.unitcode }],
        [
          { label: "通常ユーザー", value: "0" },
          { label: "一時ユーザー", value: "1" },
        ],
        [
          { label: "ロック中", value: "1" },
          { label: "未ロック", value: "0" },
        ],
        [
          { label: "iOS", value: "iOS" },
          { label: "Android", value: "Android" },
          { label: "なし", value: "not" },
        ]
      );
    }
  }, [open]);

  const [openLockModal, setOpenLockModal] = useState(false);
  const [openLockAllModal, setOpenLockAllModal] = useState(false);

  const [openCheckSalaryAll, setOpenCheckSalaryAll] = useState(false);
  const [openCheckLoginAll, setOpenCheckLoginAll] = useState(false);
  const [openCheckPhrAll, setOpenCheckPhrAll] = useState(false);

  const [openCheckNipoAll, setOpenCheckNipoAll] = useState(false);
  const [openCheckElAll, setOpenCheckElAll] = useState(false);

  const [openSmsModal, setOpenSmsModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [openPrintModal, setOpenPrintModal] = useState(false);
  const [openLeaveModal, setOpenLeaveModal] = useState(false);
  const [openEmail, setOpenEmail] = useState({ open: false, honbuid: "" });
  const [openUnit, setOpenUnit] = useState({
    open: false,
    honbuid: "",
    hpcode: "",
    hpname: "",
    unitcode: "",
    unitname: "",
  });
  const handleEmailClose = () => {
    setOpenEmail({ open: false, honbuid: "" });
  };
  const handleUnitClose = () => {
    setOpenUnit({ open: false, honbuid: "", hpcode: "", hpname: "", unitcode: "", unitname: "" });
  };
  const afterChageEmail = (honbuid, email) => {
    var localmeberList = membersList.map((item) => {
      if (item.honbuid === honbuid) {
        return { ...item, email: email };
      }
      return item;
    });

    setMembersList(localmeberList);
  };
  const [openNameModal, setOpenNameModal] = useState({ open: false, honbuid: "", name: "" });
  const [delid, setDelId] = useState(0);
  const handleLockModalClose = () => {
    setOpenLockModal(false);
  };
  const handleLockAllModalClose = () => {
    setOpenLockAllModal(false);
  };
  const handleCheckSalaryAllClose = () => {
    setOpenCheckSalaryAll(false);
  };
  const handleCheckLoginAllClose = () => {
    setOpenCheckLoginAll(false);
  };
  const handleCheckPhrAllClose = () => {
    setOpenCheckPhrAll(false);
  };
  const handleCheckNipoAllClose = () => {
    setOpenCheckNipoAll(false);
  };
  const handleCheckElAllClose = () => {
    setOpenCheckElAll(false);
  };

  const handleSmsModalClose = () => {
    setOpenSmsModal(false);
  };
  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handlePrintModalClose = () => {
    setOpenPrintModal(false);
  };
  const handleLeaveModalClose = () => {
    setOpenLeaveModal(false);
  };
  const handleNameModalClose = () => {
    setOpenNameModal({ open: false, honbuid: "", name: "" });
  };

  const handleInitSms = async () => {
    let strurl = API_URL + `/initSms?honbuid=${delid}`;
    const result = await custom_axios(strurl);
    const status = result.status;
    setSmsSnackOpen(true);
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      var resultlist = [];

      for (let j = 0; j < membersList.length; j++) {
        var inserted = false;
        for (let i = 0; i < rowId.length; i++) {
          if (rowId[i] == membersList[j].honbuid) {
            resultlist.push({ ...membersList[j], isphone: "" });
            inserted = true;
          }
        }
        if (inserted == false) {
          resultlist.push({ ...membersList[j] });
        }
      }
      setMembersList(resultlist);

      setOpenSmsModal(false);
    }
  };

  const handleInitPass = async () => {
    let strurl = API_URL + `/initPasswordRand?honbuid=${delid}`;
    const result = await custom_axios(strurl);
    const status = result.status;
    setPassSnackOpen(true);
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      setOpenModal(false);
    }
  };
  const handleLeave = async () => {
    //if (delid < 1000000) setOpenLeaveModal(false);
    let strurl = API_URL + `/leaveuser?honbuid=${delid}`;
    const result = await custom_axios(strurl);
    const status = result.status;

    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      await afterSaveProc();
      setLeaveSnackOpen(true);
      setOpenLeaveModal(false);
    }
  };
  const handleInitLockAll = async () => {
    setOpenLockAllModal(false);
    let strhonbuids = rowId
      .map((e) => {
        return e;
      })
      .join("_");

    const body = {
      honbuids: strhonbuids,
    };
    const result = await custom_axios_post(API_URL + "/initLockAll", body);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      //await afterSaveProc();
      var resultlist = [];

      for (let j = 0; j < membersList.length; j++) {
        var inserted = false;
        for (let i = 0; i < rowId.length; i++) {
          if (rowId[i] == membersList[j].honbuid) {
            resultlist.push({ ...membersList[j], islocked: "0" });
            inserted = true;
          }
        }
        if (inserted == false) {
          resultlist.push({ ...membersList[j] });
        }
      }
      setMembersList(resultlist);

      setLockSnackOpen(true);
    }
  };

  const handleCheckSalaryAll = async (flag) => {
    setOpenCheckSalaryAll(false);
    let strhonbuids = rowId
      .map((e) => {
        return e;
      })
      .join("_");

    const body = {
      honbuids: strhonbuids,
      flag: flag,
    };
    const result = await custom_axios_post(API_URL + "/CheckSalaryAll", body);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      //await afterSaveProc();
      var resultlist = [];

      for (let j = 0; j < membersList.length; j++) {
        var inserted = false;
        for (let i = 0; i < rowId.length; i++) {
          if (rowId[i] == membersList[j].honbuid) {
            resultlist.push({ ...membersList[j], issalary: flag == "on" ? "1" : "0" });
            inserted = true;
          }
        }
        if (inserted == false) {
          resultlist.push({ ...membersList[j] });
        }
      }
      setMembersList(resultlist);

      setSalaryAllSnackOpen(true);
    }
  };

  const handleCheckLoginAll = async (flag) => {
    setOpenCheckLoginAll(false);
    let strhonbuids = rowId
      .map((e) => {
        return e;
      })
      .join("_");

    const body = {
      honbuids: strhonbuids,
      flag: flag,
    };
    const result = await custom_axios_post(API_URL + "/CheckLoginAll", body);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      //await afterSaveProc();
      var resultlist = [];

      for (let j = 0; j < membersList.length; j++) {
        var inserted = false;
        for (let i = 0; i < rowId.length; i++) {
          if (rowId[i] == membersList[j].honbuid) {
            resultlist.push({ ...membersList[j], isloginok: flag == "on" ? "1" : "0" });
            inserted = true;
          }
        }
        if (inserted == false) {
          resultlist.push({ ...membersList[j] });
        }
      }
      setMembersList(resultlist);

      setLoginAllSnackOpen(true);
    }
  };

  const handleCheckPhrAll = async (flag) => {
    setOpenCheckPhrAll(false);
    let strhonbuids = rowId
      .map((e) => {
        return e;
      })
      .join("_");

    const body = {
      honbuids: strhonbuids,
      flag: flag,
    };
    const result = await custom_axios_post(API_URL + "/CheckPhrAll", body);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      //await afterSaveProc();
      var resultlist = [];

      for (let j = 0; j < membersList.length; j++) {
        var inserted = false;
        for (let i = 0; i < rowId.length; i++) {
          if (rowId[i] == membersList[j].honbuid) {
            resultlist.push({ ...membersList[j], isphr: flag == "on" ? "1" : "0" });
            inserted = true;
          }
        }
        if (inserted == false) {
          resultlist.push({ ...membersList[j] });
        }
      }
      setMembersList(resultlist);

      setPhrAllSnackOpen(true);
    }
  };

  const handleCheckNipoAll = async (flag) => {
    setOpenCheckNipoAll(false);
    let strhonbuids = rowId
      .map((e) => {
        return e;
      })
      .join("_");

    const body = {
      honbuids: strhonbuids,
      flag: flag,
    };
    const result = await custom_axios_post(API_URL + "/CheckNipoAll", body);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      //await afterSaveProc();
      var resultlist = [];

      for (let j = 0; j < membersList.length; j++) {
        var inserted = false;
        for (let i = 0; i < rowId.length; i++) {
          if (rowId[i] == membersList[j].honbuid) {
            resultlist.push({ ...membersList[j], isnipo: flag == "on" ? "1" : "0" });
            inserted = true;
          }
        }
        if (inserted == false) {
          resultlist.push({ ...membersList[j] });
        }
      }
      setMembersList(resultlist);

      setNipoAllSnackOpen(true);
    }
  };
  const handleCheckElAll = async (flag) => {
    setOpenCheckElAll(false);
    let strhonbuids = rowId
      .map((e) => {
        return e;
      })
      .join("_");

    const body = {
      honbuids: strhonbuids,
      flag: flag,
    };
    const result = await custom_axios_post(API_URL + "/CheckElAll", body);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      //await afterSaveProc();
      var resultlist = [];

      for (let j = 0; j < membersList.length; j++) {
        var inserted = false;
        for (let i = 0; i < rowId.length; i++) {
          if (rowId[i] == membersList[j].honbuid) {
            resultlist.push({ ...membersList[j], iselearning: flag == "on" ? "1" : "0" });
            inserted = true;
          }
        }
        if (inserted == false) {
          resultlist.push({ ...membersList[j] });
        }
      }
      setMembersList(resultlist);

      setElAllSnackOpen(true);
    }
  };

  const handleInitLock = async () => {
    let strurl = API_URL + `/initLock?honbuid=${delid}`;
    const result = await custom_axios(strurl);
    const status = result.status;
    setLockSnackOpen(true);
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      setMembersList(
        membersList.map((item) => {
          if (item.honbuid === delid) {
            return { ...item, islocked: "0" };
          }
          return item;
        })
      );

      setOpenLockModal(false);
    }
  };

  const handleChangeAdmin = async (honbuid, value) => {
    const result = await custom_axios(API_URL + `/changeAdmin?honbuid=${honbuid}&value=${value}`);
    const status = result.status;
    setSnackOpen(true);
    if (status === 401) {
      history.replace("/loginError");
    }
  };
  const handleChangeBukaiAdmin = async (honbuid, value) => {
    const result = await custom_axios(API_URL + `/changeBukaiAdmin?honbuid=${honbuid}&value=${value}`);
    const status = result.status;
    setSnackOpen(true);
    if (status === 401) {
      history.replace("/loginError");
    }
  };

  const handleRunLock = async (honbuid) => {
    setDelId(honbuid);
    setOpenLockModal(true);
  };

  const handleRunSms = async (honbuid) => {
    setDelId(honbuid);
    setOpenSmsModal(true);
  };

  const handleRunLeave = async (honbuid) => {
    setDelId(honbuid);
    setOpenLeaveModal(true);
  };

  const handleRun = async (honbuid) => {
    setDelId(honbuid);
    setOpenModal(true);
  };
  const handleprintRun = async (honbuid) => {
    //setDelId(honbuid);
    setRowId([honbuid]);
    setOpenPrintModal(true);
  };

  const handleChangeUserOption = async (honbuid, issalary, isphr, islogin, isvip, isanpi, isnipo, iselearning) => {
    const result = await custom_axios(
      API_URL +
        `/changeUserOption?honbuid=${honbuid}&issalary=${issalary}&isphr=${isphr}&islogin=${islogin}&isvip=${isvip}&isanpi=${isanpi}&isnipo=${isnipo}&iselearning=${iselearning}`
    );
    const status = result.status;

    if (status === 200) {
      setMembersList((m) =>
        m.map((item) => {
          if (item.honbuid === honbuid) {
            return {
              ...item,
              issalary: issalary,
              isphr: isphr,
              isloginok: islogin,
              isvip: isvip,
              isanpi: isanpi,
              isnipo: isnipo,
              iselearning: iselearning,
            };
          }
          return item;
        })
      );
      setSnackOpen(true);
    } else if (status === 204) {
      // setSnackSalaryOpen(true);
    } else if (status === 401) {
      history.replace("/loginError");
    }
  };

  const calcInitvalue = (honbuid, issalary, isphr, isloginok, isvip, isanpi, isnipo, iselearning) => {
    const initvalue = [];
    if (isloginok == "1") {
      initvalue.push(USER_SELECT_OPTION[0]);
    }
    if (issalary == "1") {
      initvalue.push(USER_SELECT_OPTION[1]);
    }
    if (isphr == "1") {
      initvalue.push(USER_SELECT_OPTION[2]);
    }
    if (isnipo == "1") {
      initvalue.push(USER_SELECT_OPTION[3]);
    }
    if (iselearning == "1") {
      initvalue.push(USER_SELECT_OPTION[4]);
    }

    if (isvip == "1") {
      initvalue.push(USER_SELECT_OPTION[5]);
    }
    if (isanpi == "1") {
      initvalue.push(USER_SELECT_OPTION[6]);
    }

    return initvalue;
  };

  const handleRunName = (honbuid, name) => {
    setOpenNameModal({ open: true, honbuid: honbuid, name: name });
  };
  const afterSaveNameProc = (honbuid, name) => {
    var localmeberList = membersList.map((item) => {
      if (item.honbuid === honbuid) {
        return { ...item, name: name };
      }
      return item;
    });

    setMembersList(localmeberList);
  };
  const MemoChangeName = useMemo(() => {
    return (
      <ShowChangeName
        open={openNameModal.open}
        handleClose={handleNameModalClose}
        afterSaveNameProc={afterSaveNameProc}
        honbuid={openNameModal.honbuid}
        username={openNameModal.name}
      ></ShowChangeName>
    );
  }, [openNameModal]);

  const MemoSMS = useMemo(() => {
    return (
      <ShowCommon
        open={openSmsModal}
        title="SMS認証を解除しますか？"
        handleClose={handleSmsModalClose}
        handleCallBack={handleInitSms}
      ></ShowCommon>
    );
  }, [openSmsModal]);
  const MemoInit = useMemo(() => {
    return (
      <ShowCommon
        open={openModal}
        title="初期化しますか？"
        handleClose={handleModalClose}
        handleCallBack={handleInitPass}
      ></ShowCommon>
    );
  }, [openModal]);
  const MemoLeave = useMemo(() => {
    return (
      <ShowCommon
        open={openLeaveModal}
        title="退職扱いにしますか？"
        handleClose={handleLeaveModalClose}
        handleCallBack={handleLeave}
      ></ShowCommon>
    );
  }, [openLeaveModal]);

  const MemoLock = useMemo(() => {
    return (
      <ShowCommon
        open={openLockModal}
        title="ロックを解除しますか？"
        handleClose={handleLockModalClose}
        handleCallBack={handleInitLock}
      ></ShowCommon>
    );
  }, [openLockModal]);
  const MemoLockAll = useMemo(() => {
    return (
      <ShowCommon
        open={openLockAllModal}
        title={rowId.length.toString() + "ユーザーのロックを解除しますか？"}
        handleClose={handleLockAllModalClose}
        handleCallBack={handleInitLockAll}
      ></ShowCommon>
    );
  }, [openLockAllModal, rowId]);

  const MemoSalaryAll = useMemo(() => {
    return (
      <ShowCommonPlus
        open={openCheckSalaryAll}
        title={rowId.length.toString() + "ユーザーの給与を一括ON/OFFしますか？"}
        handleClose={handleCheckSalaryAllClose}
        handleCallBackOn={() => handleCheckSalaryAll("on")}
        handleCallBackOff={() => handleCheckSalaryAll("off")}
      ></ShowCommonPlus>
    );
  }, [openCheckSalaryAll, rowId]);
  const MemoLoginAll = useMemo(() => {
    return (
      <ShowCommonPlus
        open={openCheckLoginAll}
        title={rowId.length.toString() + "ユーザーのログイン可否を一括ON/OFFしますか？"}
        handleClose={handleCheckLoginAllClose}
        handleCallBackOn={() => handleCheckLoginAll("on")}
        handleCallBackOff={() => handleCheckLoginAll("off")}
      ></ShowCommonPlus>
    );
  }, [openCheckLoginAll, rowId]);
  const MemoPhrAll = useMemo(() => {
    return (
      <ShowCommonPlus
        open={openCheckPhrAll}
        title={rowId.length.toString() + "ユーザーのPHRを一括ON/OFFしますか？"}
        handleClose={handleCheckPhrAllClose}
        handleCallBackOn={() => handleCheckPhrAll("on")}
        handleCallBackOff={() => handleCheckPhrAll("off")}
      ></ShowCommonPlus>
    );
  }, [openCheckPhrAll, rowId]);

  const MemoNipoAll = useMemo(() => {
    return (
      <ShowCommonPlus
        open={openCheckNipoAll}
        title={rowId.length.toString() + "ユーザーの日報を一括ON/OFFしますか？"}
        handleClose={handleCheckNipoAllClose}
        handleCallBackOn={() => handleCheckNipoAll("on")}
        handleCallBackOff={() => handleCheckNipoAll("off")}
      ></ShowCommonPlus>
    );
  }, [openCheckNipoAll, rowId]);
  const MemoElAll = useMemo(() => {
    return (
      <ShowCommonPlus
        open={openCheckElAll}
        title={rowId.length.toString() + "ユーザーのEラーニングを一括ON/OFFしますか？"}
        handleClose={handleCheckElAllClose}
        handleCallBackOn={() => handleCheckElAll("on")}
        handleCallBackOff={() => handleCheckElAll("off")}
      ></ShowCommonPlus>
    );
  }, [openCheckElAll, rowId]);
  const MemoJinji = useMemo(() => {
    return <ShowJinji open={openJin} handleClose={handleJinClose} options={options}></ShowJinji>;
  }, [openJin]);
  const MemoCreateAccount = useMemo(() => {
    return (
      <ShowCreateAccount
        open={openFa}
        handleClose={handleFaClose}
        afterSaveProc={afterSaveProc}
        setNewUser={setNewUser}
        hpcode={state.hpcode}
        hpname={state.hpname}
      ></ShowCreateAccount>
    );
  }, [openFa]);
  const MemoHonbuidInput = useMemo(() => {
    return (
      <ShowHonbuidInput
        open={openHonbuidInput}
        handleClose={handleHonbuidInputClose}
        callBackProc={searchHonbuidProc}
        afterSaveProc={afterSaveProc}
        honbuids={inputhonbuids}
        setHonbuids={setInputHonbuids}
      ></ShowHonbuidInput>
    );
  }, [openHonbuidInput, inputhonbuids]);
  const MemoShowUserEmail = useMemo(() => {
    return (
      <ShowUserEmail
        open={openEmail.open}
        honbuid={openEmail.honbuid}
        handleClose={handleEmailClose}
        callBack={afterChageEmail}
      ></ShowUserEmail>
    );
  }, [openEmail]);

  const MemoShowUserUnit = useMemo(() => {
    return (
      <ShowUnitName
        open={openUnit.open}
        honbuid={openUnit.honbuid}
        hpcode={openUnit.hpcode}
        hpname={openUnit.hpname}
        unitcode={openUnit.unitcode}
        unitname={openUnit.unitname}
        handleClose={handleUnitClose}
        callBack={afterSaveProc}
      ></ShowUnitName>
    );
  }, [openUnit]);
  const MemoNewUser = useMemo(() => {
    return <ShowNewUser open={newuser.open} handleClose={handleNewUserClose} id={newuser.honbuid}></ShowNewUser>;
  }, [newuser]);
  const MemoPrint = useMemo(() => {
    return <ShowPrint open={openPrintModal} handleClose={handlePrintModalClose} honbuids={rowId}></ShowPrint>;
  }, [openPrintModal, rowId]);

  const [selectAllClick, setSelectAllClick] = useState(false);
  const [stampAnchorEl, setStampAnchorEl] = useState(null);

  const handleSettingClick = (event) => {
    setStampAnchorEl(event.currentTarget);
  };
  const handleStampClose = () => {
    setStampAnchorEl(null);
  };

  const handleEmail = (honbuid) => {
    setOpenEmail({ open: true, honbuid: honbuid });
  };
  const handleUnit = (honbuid, hpcode, hpname, unitcode, unitname) => {
    setOpenUnit({
      open: true,
      honbuid: honbuid,
      hpcode: hpcode,
      hpname: hpname,
      unitcode: unitcode,
      unitname: unitname,
    });
  };

  const [sortModel, setSortModel] = React.useState([]);
  const [filterModel, setFilterModel] = React.useState({
    items: [],
  });
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({});
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"xl"}
      TransitionComponent={Transition}
    >
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">
          <div className="show-member-search">ユーザー一覧</div>
          <div className="question-title-actions">
            <StyledButton
              onClick={() => {
                handleFaClickOpen();
              }}
              variant="outlined"
            >
              一時ユーザー作成
            </StyledButton>
            <div style={{ width: "20px" }}></div>
            <StyledButton
              onClick={() => {
                handleJinClickOpen();
              }}
              variant="outlined"
            >
              人事連携ログ表示
            </StyledButton>
            <div style={{ width: "20px" }}></div>
            <StyledButton onClick={handleClose} variant="outlined">
              閉じる (Esc)
            </StyledButton>
          </div>
        </div>
      </StyledDialogTitle>
      <StyledDialogContent>
        {MemoChangeName}
        {MemoHonbuidInput}
        {MemoSMS}
        {MemoInit}
        {MemoLeave}
        {MemoLock}
        {MemoLockAll}
        {MemoSalaryAll}
        {MemoLoginAll}
        {MemoPhrAll}
        {MemoNipoAll}
        {MemoElAll}
        {MemoCreateAccount}
        {MemoJinji}
        {MemoNewUser}
        {MemoPrint}
        {MemoShowUserUnit}
        {MemoShowUserEmail}
        <Snackbar
          open={uppersnackopen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={() => {
            setUpperSnackOpen(false);
          }}
          message="自分より上位の権限へは操作できません。"
        ></Snackbar>
        <Snackbar
          open={snackopen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={() => {
            setSnackOpen(false);
          }}
          message="ユーザー情報を変更しました。"
        ></Snackbar>
        <Snackbar
          open={smssnackopen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={() => {
            setSmsSnackOpen(false);
          }}
          message="SMS認証を解除しました。"
        ></Snackbar>
        <Snackbar
          open={passsnackopen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={10000}
          onClose={() => {
            setPassSnackOpen(false);
          }}
          message="ランダムな値に初期化しました。QRコード印刷をしてパスワード確認をお願いします。"
        ></Snackbar>
        <Snackbar
          open={codesnackopen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={() => {
            setCodeSnackOpen(false);
          }}
          message="引き換えコードを新しくしました。"
        ></Snackbar>
        <Snackbar
          open={locksnackopen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={() => {
            setLockSnackOpen(false);
          }}
          message="ロックを解除しました。"
        ></Snackbar>
        <Snackbar
          open={salaryAllSnackOpen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={() => {
            setSalaryAllSnackOpen(false);
          }}
          message="給与を一括操作しました。"
        ></Snackbar>
        <Snackbar
          open={loginAllSnackOpen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={() => {
            setLoginAllSnackOpen(false);
          }}
          message="ログイン可否を一括操作しました。"
        ></Snackbar>
        <Snackbar
          open={phrAllSnackOpen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={() => {
            setPhrAllSnackOpen(false);
          }}
          message="PHRを一括操作しました。"
        ></Snackbar>
        <Snackbar
          open={nipoAllSnackOpen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={() => {
            setNipoAllSnackOpen(false);
          }}
          message="日報を一括操作しました。"
        ></Snackbar>
        <Snackbar
          open={elAllSnackOpen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={() => {
            setElAllSnackOpen(false);
          }}
          message="Eラーニングを一括操作しました。"
        ></Snackbar>
        <Snackbar
          open={leavesnackopen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={() => {
            setLeaveSnackOpen(false);
          }}
          message="退職扱いにしました。"
        ></Snackbar>
        <StyledRoot>
          <div className="user-select-area">
            {inputhonbuids == "" ? (
              <div className="user-select-subarea">
                <div className="multi-select">
                  <MultiSelect
                    options={options}
                    value={selectedhp}
                    onChange={(e) => {
                      setSelectedHp(e);
                      proc(e, selectedunit, selectedtemp, selectedlock, selectedos);
                    }}
                    labelledBy={"Select"}
                    overrideStrings={{
                      selectSomeItems: "選択...",
                      allItemsAreSelected: "全選択",
                      selectAll: "全選択",
                      search: "検索",
                      clearSearch: "検索クリア",
                    }}
                    filterOptions={(options, filter) => {
                      if (!filter) {
                        return options;
                      }
                      const re = new RegExp(filter, "i");
                      return options.filter(({ label }) => label && label.match(re));
                    }}
                  />
                </div>
                <div className="multi-select">
                  <MultiSelect
                    options={unitoptions}
                    value={selectedunit}
                    onChange={(e) => {
                      setSelectedUnit(e);
                      proc(selectedhp, e, selectedtemp, selectedlock, selectedos);
                    }}
                    labelledBy={"Select"}
                    overrideStrings={{
                      selectSomeItems: "選択...",
                      allItemsAreSelected: "全選択",
                      selectAll: "全選択",
                      search: "検索",
                      clearSearch: "検索クリア",
                    }}
                    filterOptions={(options, filter) => {
                      if (!filter) {
                        return options;
                      }
                      const re = new RegExp(filter, "i");
                      return options.filter(({ label }) => label && label.match(re));
                    }}
                  />
                </div>
                <div className="multi-select-mini">
                  <MultiSelect
                    options={tempoptions}
                    value={selectedtemp}
                    onChange={(e) => {
                      setSelectedTemp(e);
                      proc(selectedhp, selectedunit, e, selectedlock, selectedos);
                    }}
                    labelledBy={"Select"}
                    overrideStrings={{
                      selectSomeItems: "選択...",
                      allItemsAreSelected: "全選択",
                      selectAll: "全選択",
                      search: "検索",
                      clearSearch: "検索クリア",
                    }}
                    filterOptions={(options, filter) => {
                      if (!filter) {
                        return options;
                      }
                      const re = new RegExp(filter, "i");
                      return options.filter(({ label }) => label && label.match(re));
                    }}
                  />
                </div>

                <div className="multi-select-mini">
                  <MultiSelect
                    options={osoptions}
                    value={selectedos}
                    onChange={(e) => {
                      setSelectedOs(e);
                      proc(selectedhp, selectedunit, selectedtemp, selectedlock, e);
                    }}
                    labelledBy={"Select"}
                    overrideStrings={{
                      selectSomeItems: "選択...",
                      allItemsAreSelected: "全選択",
                      selectAll: "全選択",
                      search: "検索",
                      clearSearch: "検索クリア",
                    }}
                    filterOptions={(options, filter) => {
                      if (!filter) {
                        return options;
                      }
                      const re = new RegExp(filter, "i");
                      return options.filter(({ label }) => label && label.match(re));
                    }}
                  />
                </div>
              </div>
            ) : (
              <div></div>
            )}

            <StyledCsvDiv>
              <Tooltip title="本部ID・名前で検索">
                <IconButton color="primary" aria-label="send message" onClick={honbuidSearch}>
                  {inputhonbuids == "" ? <PageviewOutlinedIcon /> : <PageviewRoundedIcon />}
                </IconButton>
              </Tooltip>
              {/* )} */}
            </StyledCsvDiv>
          </div>
          {selectAllClick ? (
            <div className="user-select-subarea">
              <StyledIconButton color="primary" aria-label="delete" onClick={handleSettingClick}>
                <SettingsIcon />
              </StyledIconButton>
              <Menu
                id="simple-menu"
                anchorEl={stampAnchorEl}
                keepMounted
                open={Boolean(stampAnchorEl)}
                onClose={handleStampClose}
              >
                {ADMIN_BUTTON_OPTION.map((name) => (
                  <MenuItem key={name} value={name}>
                    <StyledMenuButton
                      variant="contained"
                      key={name}
                      onClick={() => {
                        if (name == "QRコード印刷プレビュー") {
                          setStampAnchorEl(null);
                          setOpenPrintModal(true);
                        }
                        if (name == "ロック解除") {
                          setStampAnchorEl(null);
                          setOpenLockAllModal(true);
                        }
                        if (name == "ログイン可一括ON/OFF") {
                          setStampAnchorEl(null);
                          setOpenCheckLoginAll(true);
                        }
                        if (name == "給与一括ON/OFF") {
                          setStampAnchorEl(null);
                          setOpenCheckSalaryAll(true);
                        }
                        if (name == "PHR一括ON/OFF") {
                          setStampAnchorEl(null);
                          setOpenCheckPhrAll(true);
                        }
                        if (name == "日報一括ON/OFF") {
                          setStampAnchorEl(null);
                          setOpenCheckNipoAll(true);
                        }
                        if (name == "Eラーニング一括ON/OFF") {
                          setStampAnchorEl(null);
                          setOpenCheckElAll(true);
                        }
                      }}
                    >
                      {name}
                    </StyledMenuButton>
                  </MenuItem>
                ))}
              </Menu>
            </div>
          ) : (
            <div></div>
          )}
          {loading ? (
            <StyledRapDataGrid>
              <StyledCircularProgress />
            </StyledRapDataGrid>
          ) : (
            <StyledRapDataGrid>
              <StyledDataGrid>
                {/* <ThemeProvider theme={theme}> */}
                <DataGrid
                  columnVisibilityModel={columnVisibilityModel}
                  onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                  filterModel={filterModel}
                  onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
                  sortModel={sortModel}
                  onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                  getRowId={(row) => row.honbuid}
                  hideFooterSelectedRowCount={true}
                  rows={membersList}
                  columns={membersListColumns}
                  pagination
                  density={"compact"}
                  checkboxSelection
                  disableRowSelectionOnClick
                  onRowSelectionModelChange={(RowId) => {
                    // 選択された行を特定するための処理
                    // const selectedRowId = new Set(RowId);
                    // const selectedRows = roomsList.filter((dataGridRow) => selectedRowId.has(dataGridRow.id));
                    // setSelectionModel(selectedRows);
                    if (RowId.length > 0) {
                      setSelectAllClick(true);
                    } else {
                      setSelectAllClick(false);
                    }
                    // console.log("rows", rows);
                    setRowId(RowId);
                  }}
                  slots={{
                    toolbar: CustomToolbar,
                    pagination: CustomPagination,
                  }}
                  // initialState={{
                  //   columns: {
                  //     columnVisibilityModel: {
                  //       honbuid: false,
                  //     },
                  //   },
                  // }}
                  // localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                ></DataGrid>
                {/* </ThemeProvider> */}
              </StyledDataGrid>
            </StyledRapDataGrid>
          )}
        </StyledRoot>
      </StyledDialogContent>
    </Dialog>
  );
}

export default ShowUser;
